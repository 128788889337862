import React from 'react'
import RESUME from '../../assets/Amalesh-Arivanan.pdf'

const CTA = () => {
  return (
    <div className="cta">
        <a href={RESUME} download className='btn'>Peek at my Resume</a>
        <a href="#contact" className='btn btn-primary'>Let's Connect</a>
    </div>
  )
}

export default CTA