import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/ndp-removebg-preview.png'
import HeaderSocials from './headersocials'
const header = () => {
  return (
    <header>
      <div className="container header__container">
        <h4>Hey, there! I am</h4>
        <h1>Amalesh Arivanan</h1>
        <h5 className="text-light">
          Software Engineer
        </h5>
        <hr className='header-line' />
        <CTA />
        <HeaderSocials />
        <div className="myimg">
          <img src={ME} alt='dp' />
        </div>
        <a href='#contact' className='scroll__down'>Scroll Down</a>
      </div>
    </header>
  )
}

export default header