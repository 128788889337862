import React from 'react'
import Header from './components/header/header'
import Nav from './components/nav/nav'
import About from './components/about/about'
import Experience from './components/experience/experience'
import Projects from './components/projects/projects'
import Contact from './components/contact/contact'
import Footer from './components/footer/footer'
import TechStack from './components/techstack/techstack'

const App = () => {
  return (
    <>
        <Header />
        <Nav />
        <About />
        <TechStack />
        <Experience />
        <Projects />
        <Contact />
        <Footer />
    </>
  )
}

export default App