import React from 'react'
import './experience.css'

const experience = () => {
  return (
    <section id='experience'>
      <h4>My Escapades</h4>
      <h1>So far</h1>
      <hr className='experience-line' /> 
      <div className="container experience__container">
        <article className='experience'>
          <div className="experience__head">
            <h3>CRASAR</h3>
            <h5>Student Worker</h5>
            <h4>Aug 2023 - May 2024</h4>
          </div>
          <ul className='experience__list'>
            <li>
              <p>
                Performed a comprehensive research study on optimal drone allocation in disaster scenarios, utilizing deterministic rounding techniques to enhance effectiveness.
              </p> 
            </li>
            <li>
              <p>
                Conducted extensive field studies to validate the theoretical models, ensuring practical applicability and reliability in real-world disaster response situations.                                                    
              </p>
            </li>
          </ul>
        </article>

        <article className='experience'>
          <div className="experience__head">
            <h3>KaaShiv Infotech</h3>
            <h5>Research Intern</h5>
            <h4>May 2021 - Jun 2021</h4>
          </div>
          <ul className='experience__list'>
            <li>
              <p>
                Supported a research study on age prediction using brain MRI images with Extreme Learning Machines (ELM) by assisting in data collection, curation, cleaning, and preprocessing.
              </p> 
            </li>
            <li>
              <p>
                Conducted data analysis and interpretation to evaluate the performance and effectiveness of the age prediction model.
              </p>
            </li>
          </ul>
        </article>

        <article className='experience'>
          <div className="experience__head">
            <h3>FashioNXT</h3>
            <h5>Software Engineer - Academic</h5>
            <h4>Jan 2023 - May 2023</h4>
            <h4>Jan 2024 - May 2024</h4>
          </div>
          <ul className='experience__list'>
          <li>
              <p>
              Led, coordinated, strategized, and directed a team of five, fostering Agile development. Facilitated collaboration with the client, write and ship customer-facing code, identification of user stories, track tasks, analysis & resolution of bugs, maintain & refactorization of existing code, design tests following TDD, evaluation of crucial scenarios, incorporate CI/CD practices to develop and document the progress.
              </p>
            </li>
            <li>
              <p>Primary responsibility comprised integration of Stripe payment gateway with the Ruby on Rails app 'CastNXT', work, assist, perform, & interact with the team in an efficient manner, troubleshoot, and active participation in team meetings, testing automation, quality assurance, error identification, test scripts, Internal system testing, test automation frameworks.</p>
            </li>
          </ul>
        </article>

        <article className='experience'>
          <div className="experience__head">
            <h3>FashioNXT</h3>
            <h5>Software Engineer - Academic</h5>
            <h4>Jan 2023 - May 2023</h4>
            <h4>Jan 2024 - May 2024</h4>
          </div>
          <ul className='experience__list'>
            <li>
              <p>Additional responsibilities encompassed coordinating with other teams towards incorporating the Ruby on Rails app under the FashioNXT umbrella, creating an additional verification mechanism for all three apps and as a mobile app. More Information under the Projects tab</p>
            </li>
            <div className="experience__to__project">
              <a href='#projects' className='btn'>Click here</a>
            </div>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default experience