import React from 'react'
import { BsLinkedin } from 'react-icons/bs';
import { BsGithub } from 'react-icons/bs';

const headersocials = () => {
  return (
    <div className="header__socials">
        <a href='https://www.linkedin.com/in/amalesh-arivanan/'><BsLinkedin /></a>
        <a href='https://www.github.com/Amalesh-A/'><BsGithub /></a>
    </div>
  )
}

export default headersocials