/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import './footer.css'
import { BsGithub, BsInstagram, BsLinkedin } from 'react-icons/bs'
import { MdEmail } from 'react-icons/md'

const footer = () => {
  return (
    <footer>
      <a href='#' className='footer__logo'>AMALESH ARIVANAN</a>
      <hr className='footer-line1' />
      <ul className='permalinks'>
        <li><a href='#' className='footertopic'>Home</a></li>
        <li><a href='#about' className='footertopic'>About</a></li>
        <li><a href='#techstack' className='footertopic'>Tech Stack</a></li>
        <li><a href='#experience' className='footertopic'>Experience</a></li>
        <li><a href='#projects' className='footertopic'>Projects</a></li>
        <li><a href='#contact' className='footertopic'>Contact</a></li>
      </ul>
      <div className="footer_contact">
        <a href='#contact' className='footertopic'><MdEmail /></a>
        <a href='#contact' className='footertopic'><BsLinkedin /></a>
        <a href='#contact' className='footertopic'><BsGithub /></a>
        <a href='#contact' className='footertopic'><BsInstagram /></a>
      </div>
    </footer>
  )
}

export default footer