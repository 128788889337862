import React from 'react'
import './contact.css'
import { HiMail } from "react-icons/hi";
import { FaGithub, FaInstagram, FaLinkedin } from 'react-icons/fa6';
import { useRef } from 'react';
import emailjs from 'emailjs-com'

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_nj55sf7', 'template_5tsnabd', form.current, 'WUURDoic5gEaVPcfV')
      e.target.reset()

  };
  return (
    <section id='contact'>
      <h4>Always Happy To</h4>
      <h1>Connect!</h1>
      <hr className='contact-line' />
      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <HiMail className='contact__icons' />
            <h4>Email</h4>
            <h5>amalesharivanan.official@gmail.com</h5>
            <a href='mailto:amalesharivanan.official@gmail.com'>Ping my Inbox!</a>
          </article>

          <article className='contact__option'>
            <FaLinkedin className='contact__icons' />
            <h4>LinkedIn</h4>
            <h5>Amalesh Arivanan</h5>
            <a href='https://www.linkedin.com/in/amalesh-arivanan/'>Let's Connect!</a>
          </article>

          <article className='contact__option'>
            <FaGithub className='contact__icons' />
            <h4>GitHub</h4>
            <h5>Amalesh-A</h5>
            <a href='https://github.com/Amalesh-A'>Dive into my Profile!</a>
          </article>

          <article className='contact__option'>
            <FaInstagram className='contact__icons' />
            <h4>Instagram</h4>
            <h5>_a.m.a.l.e.s.h_</h5>
            <a href='https://www.instagram.com/_a.m.a.l.e.s.h_/'>DM to know more!</a>
          </article>
        </div>
        {/*END OF CONTACT*/}
        <form ref={form} onSubmit={sendEmail}>
          <input type='text' name='name' placeholder='Your Full Name, Please :).' required />
          <input type='email' name='email' placeholder='Your Email, Please :).' required />
          <input type='subject' name='subject' placeholder='Reason for reaching out? Please :).' required />
          <textarea name="message" id="" rows="7" placeholder='Your Message for me'></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact