/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import './nav.css'
import { IoMdHome } from 'react-icons/io'
import { FaBriefcase } from "react-icons/fa";
import { FaFolderClosed } from "react-icons/fa6";
import { MdContacts } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import { useState } from 'react';
import { FaClipboardList } from "react-icons/fa";

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')
  return (
    <nav>
      <a href='#' onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}><IoMdHome /></a>
      <a href='#about' onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}><FaUserTie /></a>
      <a href='#techstack' onClick={() => setActiveNav('#techstack')} className={activeNav === '#techstack' ? 'active' : ''}><FaClipboardList /></a>
      <a href='#experience' onClick={() => setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}><FaBriefcase /></a>
      <a href='#projects' onClick={() => setActiveNav('#projects')} className={activeNav === '#projects' ? 'active' : ''}><FaFolderClosed /></a>
      <a href='#contact' onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><MdContacts /></a>
    </nav>
  )
}

export default Nav