import React from 'react'
import './about.css'
import IMG from '../../assets/prof.jpg'
import { BiSolidAward } from "react-icons/bi";
import { FaFolderOpen } from "react-icons/fa6";
import { RiTeamFill } from "react-icons/ri";
const about = () => {
  return (
    <section id='about'>
      <h4>Explore</h4>
      <h1>My Story</h1>
      <hr className='about-line' />
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-img">
            <img src={IMG} alt='profpic' />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
          <article className='about__card'>
            <BiSolidAward className='about__icon'/>
            <h5>Education</h5>
            <hr className='icon-line' />
            <small>MSCS</small>
            <small> Texas A&M University</small>
          </article>

          <article className='about__card'>
            <FaFolderOpen className='about__icon'/>
            <h5>Projects</h5>
            <hr className='icon-line' />
            <small>18+ Projects </small>
            <small> Academic & Personal</small>
          </article>

          <article className='about__card'>
            <RiTeamFill className='about__icon'/>
            <h5>Leadership</h5>
            <hr className='icon-line' />
            <small>Led and coordinated a 2-day nation-wide event as a part of 'Make A Difference'. Strategized and orchestrated a 3-day national level event - 'Project Expo'.</small>
          </article>
          </div>
          <p>Enthusiastic and outcome-oriented software developer with expertise in AI, machine learning, and deep learning, alongside extensive experience in Cloud Native Application Development. Proficient in public cloud environments, concurrency, memory management, and distributed databases. Familiar with the software development lifecycle, including designing, developing, deploying, and maintaining web and mobile apps. Adept at researching, designing, developing, and testing software for distributed components, building new features, and improving existing products. Skilled in in-memory cache implementations and MERN applications. Seeking a Software Engineer position to leverage my technical expertise and collaborative mindset. Committed to producing high-quality code, mastering internal development standards, and utilizing modern frameworks and agile methodologies to deliver impactful solutions and drive software excellence.</p>
          <a href='#contact' className='btn btn-primary'>Let's connect!</a>
          <hr className='ic-line' />
        </div>
      </div>
    </section>
  )
}

export default about