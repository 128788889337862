import React from 'react'
import './projects.css'

const projects = () => {
  return (
    <section id='projects'>
      <h4>Explore</h4>
      <h1>My Projects</h1>
      <hr className='projects-line' />
      <div className="container projects__container">
        <div className="project1">
          <h3>Drone-Resource Allocation</h3> 
          <hr className='icon-line' />
          <div className="project__content">
            <article className='project__details'>
              <p>In the event of an unforeseen disaster, the most crucial task is to allocate the necessary resources to the tasks that require them.</p>
              <small>Year: Jan 2024 - May 2024</small>
            </article>
          </div>
          <a href='https://github.com/Amalesh-A/GAP' className='btn'>Link</a>
        </div>
        
        <div className="project2">
        <h3>EventNXT</h3> 
          <hr className='icon-line' />
          <div className="project__content">
            <article className='project__details'>
              <p>Live events commit tickets through various channels -- (1) Box-office ticket sale and (2) VIP guest list. For the event owner keeping a count of tickets in real-time  is critical to get a clear picture of the available seats so s/he can strategize event promotion strategy as well as ensures s/he isn’t over-commiting beyond the seating capacity. 
              Incentivizing guests to refer their friends to the event offering rewards that are conditional to the friends buying tickets to the event.</p>
              <small>Year: Jan 2024 - May 2024</small>
            </article>
          </div>
        </div>

        <div className="project3">
        <h3>PlaNXT</h3> 
          <hr className='icon-line' />
          <div className="project__content">
            <article className='project__details'>
              <p>A project management tool for events producers who are responsible for venue floor-planning, setup/breakdown and staff placement for execution of the event. It’s an automation tool that enables the event planner to create a plan that chronologically displays the state of the setup of the event, and associated staff placement to execute the setup/breakdown as well as the operation of the event. </p>
              <small>Year: Jan 2024 - May 2024</small>
            </article>
          </div>
        </div>

        <div className="project4">
        <h3>CastNXT</h3> 
          <hr className='icon-line' />
          <div className="project__content">
            <article className='project__details'>
              <p>A communication hub for the Producer of an event with the show-Clients and show Service-providers (Cast, contractors). Any show/event/festival Producer/Casting Manager secures/negotiates for with his/her clients (designers, stage-managers). The resources include: talent allocation, look/styling of the talents, rental gears, or other resources that need to be allocated to the clients for making the show presentation happen.</p>
              <small>Year: Jan 2024 - May 2024</small>
            </article>
          </div>
        </div>

        <div className="project5">
        <h3>Event360</h3> 
          <hr className='icon-line' />
          <div className="project__content">
            <article className='project__details'>
              <p>A user management and analytics tracking application for the group of applications managed by FashioNXT built using Ruby on Rails.</p>
              <small>Year: Jan 2024 - May 2024</small>
            </article>
          </div>
        </div>

        <div className="project6">
        <h3>iAmalesh</h3> 
          <hr className='icon-line' />
          <div className="project__content">
            <article className='project__details'>
              <p>An interactive personal project that mimics a social networking application to showcase my skills and projects.</p>
              <small>Year: Dec 2023 - Present</small>
            </article>
          </div>
          <a href='https://github.com/Amalesh-A/iAmalesh' className='btn'>Link</a>
        </div>

        <div className="project7">
        <h3>OpenMP and CUDA</h3> 
          <hr className='icon-line' />
          <div className="project__content">
            <article className='project__details'>
              <p>Implement Shared-Memory Programming with Threads, Distributed-Memory Programming with MPI, Parallel Merge Sort Using Threads, Parallel Merge Sort Using OpenMP, Parallel Programming with MPI − Hypercube Quicksort, and Parallelizing Strassen’s Matrix-Multiplication Algorithm</p>
              <small>Year: Aug 2023 - Dec 2023</small>
            </article>
          </div>
          <a href='https://github.com/Amalesh-A/OpenMP-and-CUDA' className='btn'>Link</a>
        </div>

        <div className="project8">
        <h3>BookWormsRUs</h3> 
          <hr className='icon-line' />
          <div className="project__content">
            <article className='project__details'>
              <p>An online book-store MERN application </p>
              <small>Year: May 2023 - Sep 2023</small>
            </article>
          </div>
          <a href='https://github.com/Amalesh-A/BookWormsRUs' className='btn'>Link</a>
        </div>

        <div className="project9">
        <h3>ShopsALot!</h3> 
          <hr className='icon-line' />
          <div className="project__content">
            <article className='project__details'>
              <p>A shopping application that allows a user to add a product, modify an order, delete an item, and make payments as well as get notified of all observed actions.</p>
              <small>Year: Jan 2023 - Mar 2023</small>
            </article>
          </div>
          <a href='https://github.com/Amalesh-A/shopsalot' className='btn'>Link</a>
        </div>

        <div className="project10">
        <h3>Robo-Romp</h3> 
          <hr className='icon-line' />
          <div className="project__content">
            <article className='project__details'>
              <p>Designed, developed, and implemented specific functionalities and behaviors of Survivor Buddy and Spots. Collaborated with the team to enhance the functionality and user experience of both projects.</p>
              <small>Year: Jan 2024 - Apr 2024</small>
            </article>
          </div>
        </div>

        <div className="project11">
        <h3>x86 based mini-kernel</h3> 
          <hr className='icon-line' />
          <div className="project__content">
            <article className='project__details'>
              <p>Developed a mini-kernel based on x86 in C++, including memory management with contiguous frame allocation, a page table, and a virtual memory manager. Implemented thread management with FIFO and round-robin schedulers, and created a hard disk driver and file system APIs for data storage and retrieval. </p>
              <small>Year: Jan 2023 - May 2023</small>
            </article>
          </div>
        </div>

        <div className="project12">
        <h3>Identifying, Analyzing, and Optimizing Quantum Error Correction and Fault Tolerance Challenges</h3> 
          <hr className='icon-line' />
          <div className="project__content">
            <article className='project__details'>
              <p>Pursued a research study that seeks to identify and evaluate error sources, improve error-correction and fault-tolerance protocols, and characterize the performance of quantum devices, as well as, highlight recent advancements in the field and emphasize the future research directions.</p>
              <small>Year: Jan 2023 - May 2023</small>
            </article>
          </div>
        </div>

        <div className="project13">
        <h3>Flight Ticket Price Analysis</h3> 
          <hr className='icon-line' />
          <div className="project__content">
            <article className='project__details'>
              <p>Designed and developed a web scraper that selects a website serving flight booking facilities, fed-in manipulated details through an automated fashion, and fetch the ticket price details.</p>
              <small>Year: Feb 2023 - Feb 2023</small>
            </article>
          </div>
        </div>

        <div className="project14">
        <h3>Identifying, Analyzing, and Optimizing Quantum Error Correction and Fault Tolerance Challenges</h3> 
          <hr className='icon-line' />
          <div className="project__content">
            <article className='project__details'>
              <p>Pursued a research study that seeks to identify and evaluate error sources, improve error-correction and fault-tolerance protocols, and characterize the performance of quantum devices, as well as, highlight recent advancements in the field and emphasize the future research directions.</p>
              <small>Year: Mar 2023 - May 2023</small>
            </article>
          </div>
        </div>

        <div className="project15">
        <h3>CIFAR-10 Image Classification Using Neural Networks</h3> 
          <hr className='icon-line' />
          <div className="project__content">
            <article className='project__details'>
              <p>Built a EfficientNetV2 Model for CIFAR-10 image classification, achieving an accuracy of 86%.</p>
              <small>Year: Aug 2022 - Dec 2022</small>
            </article>
          </div>
        </div>

        <div className="project16">
        <h3>Deep Residual Networks for CIFAR-10 Image Classification</h3> 
          <hr className='icon-line' />
          <div className="project__content">
            <article className='project__details'>
              <p>Designed and developed a residual block structure for image classification using CIFAR-10 image datasets while exploring hyperparameters such as ResNet versions(ResNet-18v1 and ResNet-18v2), number of residual blocks per stack layer, number of training iterations, batch size, and learning rate and calculated the validation loss and validation error. </p>
              <small>Year: Aug 2022 - Dec 2022</small>
            </article>
          </div>
        </div>

        <div className="project17">
        <h3>Image Classification using Recurrent Neural Networks</h3> 
          <hr className='icon-line' />
          <div className="project__content">
            <article className='project__details'>
              <p>Designed and developed a recurrent neural network model for image classification. Hyperparameters such as batch size, learning rate, embedding size, hidden dimensions, dropout etc., and results were tabulated. </p>
              <small>Year: Aug 2022 - Dec 2022</small>
            </article>
          </div>
        </div>

        <div className="project18">
        <h3>Kernel based models in Deep Learning</h3> 
          <hr className='icon-line' />
          <div className="project__content">
            <article className='project__details'>
              <p>Designed and developed Kernel Logistic Regression(KLR) model, Radial Basis Function model, Feed-Forward Neural Network(FNN) model.</p>
              <small>Year: Aug 2022 - Dec 2022</small>
            </article>
          </div>
        </div>

        <div className="project19">
        <h3>A Holistic and Semantic Optimum Driven Canis Lupus Familiaris Breed Classification Based On Deep Learning</h3> 
          <hr className='icon-line' />
          <div className="project__content">
            <article className='project__details'>
              <p>Classified dog breeds using image processing and deep learning methods. Employed conventional approaches such as Local Binary Pattern (LBP) and Histogram of Oriented Gradient (HOG), as well as deep learning techniques using deep convolutional neural networks (CNN). Addressed issues related to population control, disease outbreaks like Rabies, vaccination tracking, and legal ownership by accurately identifying individuals and their breeds. </p>
              <small>Year: Jan 2022 - May 2022</small>
            </article>
          </div>
        </div>

        <div className="project20">
        <h3>Taking a Deep-Bidirectional LSTM Approach for Tackling the Malicious URL Detection Problem</h3> 
          <hr className='icon-line' />
          <div className="project__content">
            <article className='project__details'>
              <p>Published a research paper in the International Journal of Computer Science Trends and Technology (Volume 9 Issue 6 / Nov - Dec 2021). </p>
              <small>Year: Jun 2021 - Sep 2021</small>
            </article>
          </div>
        </div>

        <div className="project21">
        <h3>Weather Monitoring Using Internet Of Things</h3> 
          <hr className='icon-line' />
          <div className="project__content">
            <article className='project__details'>
              <p>Designed and developed a weather prediction system using Arduino and Neural Networks and won the appreciation of the judges in the 3rd annual SRM Project Expo in Feb 2020.</p>
              <small>Year: Jan 2020 - Feb 2020</small>
            </article>
          </div>
        </div>
      </div>


    </section>
  )
}

export default projects