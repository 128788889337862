import React from 'react'
import './techstack.css'
import { FaHtml5 } from "react-icons/fa";
import { FaCss3Alt } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io5";
import { FaBootstrap } from "react-icons/fa6";
import { PiFileSqlFill } from "react-icons/pi";
import { SiMongodb } from "react-icons/si";
import { SiPostgresql } from "react-icons/si";
import { RiFirebaseFill } from "react-icons/ri";
import { FaPython } from "react-icons/fa";
import { BsFillPatchCheckFill } from 'react-icons/bs';
import { FaPhp } from "react-icons/fa";
import { FaAws } from "react-icons/fa";
import { SiGooglecloud } from "react-icons/si";
import { SiMicrosoftazure } from "react-icons/si";
import { BsNvidia } from "react-icons/bs";
import { SiFramework } from "react-icons/si";
import { FaWindows } from "react-icons/fa";
import { FaLinux } from "react-icons/fa";
import { FaDocker } from "react-icons/fa6";
import { BiLogoKubernetes } from "react-icons/bi";
import { FaReact } from "react-icons/fa";
import { FaVuejs } from 'react-icons/fa';
import { FaAngular } from "react-icons/fa";
import { SiApachekafka } from "react-icons/si";
import { SiTensorflow } from "react-icons/si";
import { SiPandas } from "react-icons/si";
import { SiPytorch } from "react-icons/si";
import { SiScikitlearn } from "react-icons/si";

const techstack = () => {
  return (
    <section id='techstack'>
        <h4>What do I bring</h4>
        <h1>My Skillset</h1>
        <hr className='tech-line' />                               
        <div className="container techstack__container">
            <div className="techstack__frontend">
                <h3>Frontend Development</h3> 
                <hr className='icon-line' />                               
                <div className="techstack__content">
                    <article className='techstack__details'>
                        <FaHtml5 className='techstack__details-icon' />
                        <div>
                            <h4>HTML</h4>
                            <small className='text-light'>Experienced</small>
                        </div>
                    </article>
                    <article className='techstack__details'>
                        <FaCss3Alt className='techstack__details-icon' />
                        <div>
                            <h4>CSS</h4>
                            <small className='text-light'>Experienced</small>
                        </div>
                    </article>
                    <article className='techstack__details'>
                        <IoLogoJavascript className='techstack__details-icon' />
                        <div>
                            <h4>Javascript</h4>
                            <small className='text-light'>Experienced</small>
                        </div>
                    </article>
                    <article className='techstack__details'>
                        <FaBootstrap className='techstack__details-icon' />
                        <div>
                            <h4>Bootstrap</h4>
                            <small className='text-light'>Intermediate</small>
                        </div>
                    </article>
                </div>
            </div>

            <div className="techstack__backend">
                <h3>Backend Development</h3> 
                <hr className='icon-line' />
                <div className="techstack__content">
                    <article className='techstack__details'>
                        <PiFileSqlFill className='techstack__details-icon' />
                        <div>
                            <h4>MySQL</h4>
                            <small className='text-light'>Experienced</small>
                        </div>
                    </article>
                    <article className='techstack__details'>
                        <SiMongodb className='techstack__details-icon' />
                        <div>
                            <h4>MongoDB</h4>
                            <small className='text-light'>Intermediate</small>
                        </div>
                    </article>
                    <article className='techstack__details'>
                        <SiPostgresql className='techstack__details-icon' />
                        <div>
                            <h4>PostGres</h4>
                            <small className='text-light'>Intermediate</small>
                        </div>
                    </article>
                    <article className='techstack__details'>
                        <RiFirebaseFill className='techstack__details-icon' />
                        <div>
                            <h4>Firebase</h4>
                            <small className='text-light'>Intermediate</small>
                        </div>
                    </article>
                </div>       
            </div>
            
            {/*FRONTEND TECH*/}
            
            <div className="techstack__languages">
                <h3>Programming Languages</h3>   
                <hr className='icon-line' />                             
                <div className="techstack__content">
                    <article className='techstack__details'>
                        <BsFillPatchCheckFill className='techstack__details-icon' />
                        <div>
                            <h4>C</h4>
                            <small className='text-light'>Experienced</small>
                        </div>
                    </article>
                    <article className='techstack__details'>
                        <BsFillPatchCheckFill className='techstack__details-icon' />
                        <div>
                            <h4>C++20</h4>
                            <small className='text-light'>Experienced</small>
                        </div>
                    </article>
                    <article className='techstack__details'>
                        <FaPython className='techstack__details-icon' />
                        <div>
                            <h4>Python</h4>
                            <small className='text-light'>Experienced</small>
                        </div>
                    </article>
                    <article className='techstack__details'>
                        <FaPhp className='techstack__details-icon' />
                        <div>
                            <h4>PHP</h4>
                            <small className='text-light'>Experienced</small>
                        </div>
                    </article>
                    <article className='techstack__details'>
                        <BsFillPatchCheckFill className='techstack__details-icon' />
                        <div>
                            <h4>R</h4>
                            <small className='text-light'>Intermediate</small>
                        </div>
                    </article>
                </div>
            </div>

            {/*BACKEND TECH*/}

            <div className="techstack__cloud">
                <h3>Cloud Technologies</h3> 
                <hr className='icon-line' />
                <div className='techstack__content'>
                    <article className='techstack__details'>
                        <FaAws className='techstack__details-icon' />
                        <div>
                            <h4>AWS</h4>
                            <small className='text-light'>Experienced</small>
                        </div>
                    </article>
                    <article className='techstack__details'>
                        <SiMicrosoftazure className='techstack__details-icon' />
                        <div>
                            <h4>Azure</h4>
                            <small className='text-light'>Intermediate</small>
                        </div>
                        </article>
                    <article className='techstack__details'>
                        <SiGooglecloud className='techstack__details-icon' />
                        <div>
                            <h4>GCP</h4>
                            <small className='text-light'>Beginner</small>
                        </div>
                    </article>
                </div>                               
            </div>

            {/*CLOUD TECH*/}

            <div className="techstack__programmingframeworks">
                <h3>Programming Frameworks</h3>       
                <hr className='icon-line' /> 
                <div className='techstack__content'>
                    <article className='techstack__details'>
                        <BsNvidia className='techstack__details-icon' />
                        <div>
                            <h4>CUDA</h4>
                            <small className='text-light'>Intermediate</small>
                        </div>
                    </article>
                    <article className='techstack__details'>
                        <SiFramework className='techstack__details-icon' />
                        <div>
                            <h4>OpenMP</h4>
                            <small className='text-light'>Intermediate</small>
                        </div>
                    </article>
                </div>                        
            </div>

            {/*PRG FRAMEWORKS*/}

            <div className="techstack__os">
                <h3>Operating Systems</h3>
                <hr className='icon-line' />
                <div className='techstack__content'>
                    <article className='techstack__details'>
                        <FaWindows className='techstack__details-icon' />
                        <div>
                            <h4>Windows</h4>
                            <small className='text-light'>Experienced</small>
                        </div>
                    </article>
                    <article className='techstack__details'>
                        <FaLinux className='techstack__details-icon' />
                        <div>
                            <h4>Ubuntu-22.04</h4>
                            <small className='text-light'>Intermediate</small>
                        </div>
                    </article>
                </div>
            </div>

            {/*OS*/}

            <div className="techstack__containerization">
                <h3>Containerization Tools</h3>
                <hr className='icon-line' />
                <div className='techstack__content'>
                    <article className='techstack__details'>
                        <FaDocker className='techstack__details-icon' />
                        <div>
                            <h4>Docker</h4>
                            <small className='text-light'>Intermediate</small>
                        </div>
                    </article>
                    <article className='techstack__details'>
                        <BiLogoKubernetes className='techstack__details-icon' />
                        <div>
                            <h4>Kubernetes</h4>
                            <small className='text-light'>Intermediate</small>
                        </div>
                    </article>
                </div>
            </div>

            {/*FRAMEWORKS*/}

            <div className="techstack__framework">
                <h3>Frameworks</h3>                 
                <hr className='icon-line' />               
                <div className='techstack__content'>
                    <article className='techstack__details'>
                        <FaReact className='techstack__details-icon' />
                        <div>
                            <h4>React</h4>
                            <small className='text-light'>Experienced</small>
                        </div>
                    </article>
                    <article className='techstack__details'>
                        <FaAngular className='techstack__details-icon' />
                        <div>
                            <h4>Angular</h4>
                            <small className='text-light'>Intermediate</small>
                        </div>
                    </article>
                    <article className='techstack__details'>
                        <FaVuejs className='techstack__details-icon' />
                        <div>
                            <h4>Vue</h4>
                            <small className='text-light'>Intermediate</small>
                        </div>
                    </article>
                    <article className='techstack__details'>
                        <SiApachekafka className='techstack__details-icon' />
                        <div>
                            <h4>Kafka</h4>
                            <small className='text-light'>Beginner</small>
                        </div>
                    </article>
                </div>
            </div>

            {/*LIBRARIES*/}

            <div className="techstack__libraries">
                <h3>Python Libraries</h3>
                <hr className='icon-line' />
                <div className='techstack__content'>
                    <article className='techstack__details'>
                        <SiTensorflow className='techstack__details-icon' />
                        <div>
                            <h4>Tensorflow</h4>
                            <small className='text-light'>Experienced</small>
                        </div>
                    </article>
                    <article className='techstack__details'>
                        <SiPytorch className='techstack__details-icon' />
                        <div>
                            <h4>PyTorch</h4>
                            <small className='text-light'>Intermediate</small>
                        </div>
                    </article>
                    <article className='techstack__details'>
                        <SiPandas className='techstack__details-icon' />
                        <div>
                            <h4>Pandas</h4>
                            <small className='text-light'>Experienced</small>
                        </div>
                    </article>
                    <article className='techstack__details'>
                        <SiScikitlearn className='techstack__details-icon' />
                        <div>
                            <h4>Scikit</h4>
                            <small className='text-light'>Intermediate</small>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </section>
  )
}

export default techstack